import Step from "@/components/global/Step/Step.vue";
import ProductList from "../components/ProductList.vue";
import ProductModal from "@/views/stockCenter/adjustmentApply/components/ProductModal.vue";
import {
  // 分销商 分销地址
  showAnother,
  // 收货地址切换带出联系人
  // 获取城市
  cityChange,
  // 获取城镇 区
  townChange,
  // 获取城镇区下一级
  getQu,
  // 分销商切换 获取新分销商地址
  getDISAdd,
  // 要求到货周
  // getWeek,
  // 商品价格列表
  goodsList,
  // 获取商品列表库存数
  getNumber,
  // 调用五遍接口
  getSupplierS,
  // dms配送方式
  getDeliveryMode,
  // 查询商品行信息
  getCustomer,
  // 订单提交
  orderSubmit,
  // 用户模糊搜索
  getUser,
  // 获取渠道订单用户详情
  getUserMessage,
  findMaterialByCustomer,
  queryShareFlag,
  getWarehouseListApi,
  sysconfig
} from "./api";
import moment from "moment";
import { getProductList } from "@/views/channelInput/components/api";
import { isEnableOrNotBucha } from "@/views/stockCenter/adjustmentApply/status/api";
import { getAddress } from "@/views/channel/api";
import { getFavori } from "@/views/favorites/api";

export default {
  name: "Home",
  components: {
    Step,
    ProductList,
    ProductModal
  },
  props: {
    // orderType: {
    //   type: String,
    //   required: true
    // }
  },
  data() {
    return {
      rowSelectionType: "checkbox", //是否编辑
      selRow: {}, //当前编辑的行
      modelShow: false,
      bankChecked: true,
      visible: false,
      steps: ["录入订单", "确认订单信息", "提交订单"],
      currentStep: 2,
      deliveryOptions: [], //配送方式
      prods: [],
      // 开票户头账号
      choseAccounts: [],
      // 开票户头id
      invoiceId: "",
      //是否允许提前发货
      allowAdvance: true,
      // 用户选择截止时间
      // endTime: '',
      // 控制分销商及分销商地址是否显示
      distributors: false,
      // 经销商下拉选择框
      distr: false,
      // 经销商地址
      distrAddress: "",
      distrAdd: false,
      distrName: "",
      // 经销商地址
      orderDis: [],
      // 选择直配到分销时填写客户单号
      orderNumber: "",
      // 配送方式选择直配到用户
      userAdderss: false,
      // 用户详细地址
      userDetailAddress: "333",
      orderType: "channelInput",
      // 省市区三级联动
      secondCity: "",
      // 接收子组件选中的商品
      sonChectItme: [],
      receiveAdd: false,
      // 收货地址/统仓地址
      receive: "",
      // 收货地址id
      receiveID: "",
      // 收货地址类型 obj.shareFlag=='Y'?'统仓地址':'收货地址'
      // 配送方式ID
      sendId: "",
      // 分销商地址选择
      distributionAddress: [],
      // 输入输入内容
      searchInt: "",
      // 省
      provices: [],
      //市
      getCity: [],
      // 城镇
      getTown: [],
      // 城镇下一级
      qu: [],
      // 省市区选择
      proObj: {},

      // 经销商地址列表
      choseDistribution: [],
      // 分销商列表
      DisList: [],
      // 经销商及经销商地址
      distributionAdd: {
        DISCODE: "",
        DISADD: ""
      },
      // 经销商切换获取地址 接口参数
      DISADDPARAMS: {
        customerId: "",
        orgId: "",
        matklId: ""
      },
      // 要求到货周
      arriveOptions: [],
      selected: "",
      GoodsList: [],
      // 商品数量、体积、价格、预付款
      goodsNum: 0,
      goodsVolume: 0,
      goodsPrice: 0,
      advancePrice: 0,
      TotalPayment: {},
      drawerName: "",
      dissize: false,
      // 点击提交按钮提交的参数
      orgAndGroup: "",
      orderCartIds: {},
      // 配送方式
      trans: "",
      hisenseOff: false,
      officehis: [],
      officeCode: "",
      receivehis: "",
      officeHis: false,
      orgCode: "",
      matklId: "",
      orgId: "",
      orgtitle: "",
      obj: {},
      // 提交给后台订单详情
      submitGoods: [],
      endTime: "",
      // 有效截止时间
      startTime: "",
      // 后台接口最大截止时间
      maxEndDate: "",
      // 面包屑
      breadcrumbData: [
        {
          path: "/index",
          name: "index",
          title: "首页"
        },
        // {
        //   path: "/cart",
        //   name: "cart",
        //   title: "订单录入"
        // },
        {
          path: "/channelNew",
          name: "channelNew",
          title: "渠道订单录入"
        }
      ],
      // 按钮组件loading
      isLoading: false,
      // 页面加载loading
      pageLoadFlag: false,
      //备注
      BZvalue: "",
      // 收货地址
      shippingAddress: [],
      totalOrderedQty: 0,
      totalAmount: 0,
      purchaseOrderItem: [],
      // 采购人员id
      userid: "",
      supplierCode: "",
      // 模糊搜索
      mohusearch: "",
      dataSource: [],
      // 发货仓库
      receiveFH: "",
      receiveFHID: "",
      orderDisFH: [],
      receiveAddFH: "",
      // 入库仓库
      receiveAddRK: "",
      receiveRKID: "",
      receiveRK: "",
      orderDisRK: [],
      billToId: "",
      // 销售类型
      // 销售类型
      saleOptions: [
        {
          name: "批发",
          id: "wholesale"
        },
        {
          name: "工程",
          id: "engineering"
        }
      ],
      productList: [],
      ywy: "",
      ywyname: "",
      XSLX: "wholesale",
      kpf: "",
      customerCode: "",
      customerName: "",
      //组件
      columns: [
        {
          label: "型号",
          prop: "model",
          ellipsis: true,
          width: "120",
          key: "model"
        },
        {
          label: "颜色",
          prop: "colour",
          width: "80",
          key: "colour"
        },
        {
          label: "物料组",
          prop: "materialGroupName",
          width: "100",
          ellipsis: true,
          key: "materialGroupName"
        },
        {
          label: "价格(元)",
          prop: "999",
          width: "100",
          key: "999"
        },
        {
          label: "体积(m³)",
          prop: "volume",
          width: "110",
          key: "volume"
        },
        {
          label: "仓库",
          prop: "warehouseName",
          width: "124",
          ellipsis: true,
          key: "warehouseName"
        },
        {
          label: "质量等级",
          prop: "invStatusName",
          width: "110",
          key: "invStatusName"
        },
        {
          label: "补差类型",
          prop: "invStatusTypeName",
          width: "110",
          key: "invStatusTypeName"
        },
        {
          label: "可用库存",
          prop: "bigQty",
          width: "110",
          key: "bigQty"
        },
        {
          label: "销售数量",
          prop: "",
          key: "borderedQty",
          minWidth: 110,
          slot: "count"
        }
      ],
      dataList: [],
      isVisible: false,
      tabelIsLoading: false,
      searchIpt: "",
      tableTotal: 0,
      pager: {
        count: 0,
        pageNo: 1,
        pageSize: 10
      },
      materialGroup: [],
      timeout: null,
      shdzId: "",
      xszzId: "",
      fhckId: "",
      isEnableOrNot: true,
      visibleshop: false,
      shopcisCode: "",
      shareType: "",
      typeW: ""
    };
  },

  mounted() {
    // dms 收货地址
    // this.cglrshdz()
    // 业务员
    this.getywy();
    // 采购录入业务员
    this.cglrywy();
    // 配送方式
    this.getPS();
    // dms订单提交
    this.submitOrder;
    var now = new Date();
    var year = now.getFullYear(); //得到年份
    var month = now.getMonth() + 1; //得到月份
    var date = now.getDate(); //得到日期
    // var day = now.getDay(); //得到周几
    var hour = now.getHours(); //得到小时
    var minu = now.getMinutes(); //得到分钟
    var sec = now.getSeconds(); //得到秒
    var time =
      year + "-" + month + "-" + date + " " + hour + ":" + minu + ":" + sec;
    var time2 = year + "-" + month + "-" + date;
    this.startTime = time2;
    this.onChange();
    this.getType();
  },
  watch: {
    // receiveID: {
    //   immediate: true,
    //   handler(newValue) {
    //     this.isEnableOrNotBuchaUpdate();
    //   }
    // }
  },
  methods: {


    // 判断是否包含免运费标识
    panduanfreeTip() {
      let mian = [];
      this.productList.forEach(item => {
        if (item.productLabel && item.productLabel.indexOf("15691143850") !== -1) {
          mian.push(item);
        }
      });
      if (mian.length > 0) {
        this.visibleshop = true;
      } else {
        this.sumbit();
      }
    },
    // isEnableOrNotBuchaUpdate() {
    //   if (!this.receiveID) {
    //     return; 
    //   }
    //   let data = {
    //     cisCode: this.$store.state.user.userInfo.customer.cisCode,
    //     orgCode: this.receiveID
    //   };
    //   isEnableOrNotBucha(data).then(res => {
    //     if(!res.data){
    //       return
    //     }
    //     const resData = JSON.parse(res.data);
    //     this.isEnableOrNot = resData.data;
    //   });
    // },
    //防抖
    debounce(fn, wait) {
      var timeout = null;
      return function() {
        if (timeout !== null) clearTimeout(timeout);
        timeout = setTimeout(fn, wait);
      };
    },
    getMetarial() {
      if (!this.customerCode) {
        this.materialGroup = [];
        return;
      }
      findMaterialByCustomer({
        orgId: "",
        customerCode: this.customerCode
      }).then(res => {
        let data;
        if (typeof res.data == "string") {
          data = JSON.parse(res.data);
        } else {
          data = res.data;
        }
        this.materialGroup = data.materialGroup;
      });
    },
    //搜索按钮事件
    search() {
      if (!this.searchIpt || this.searchIpt.length < 2) {
        // this.$message.info("请输入至少两个字符进行搜索！");
        const msg = this.$configTotal({
          route: this.$route.fullPath,
          id: "14180000066"
        });
        this.$message.info(msg && msg.msgDesc);
        return;
      }
      this.pager.pageNo = 1;
      this.getData();
    },
    pageSearch(pager) {
      this.pager.pageNo = pager.pageNo;
      this.pager.pageSize = pager.pageSize;
      this.getData();
    },
    //调接口获取数据
    getData() {
      this.tabelIsLoading = true;
      const data = {
        cisCode: this.$store.state.user.userInfo.customer.cisCode,
        terms: {
          model: this.searchIpt,
          colour: "",
          warehouseId: this.receiveFHID,
          invStatusId: "",
          isLock: "",
          dealerMaterialGroupFlag: "",
          materialGroupCode: "",
          materialGroupName: "",
          gicWarehouseType: "",
          invStatusType: "",
          invType: "",
          bigQtyMin: 0,
          orgId: this.receiveID
        },
        page: {
          pageNo: this.pager.pageNo,
          pageSize: this.pager.pageSize
        }
      };
      getProductList(data).then(res => {
        this.tabelIsLoading = false;
        let data;
        if (typeof res.data == "string") {
          data = JSON.parse(res.data);
        } else {
          data = res.data;
        }
        const start = (this.pager.pageNo - 1) * this.pager.pageSize;
        data.data.forEach(it => {
          it.borderedQty = 1;
          it.invStatus = it.invStatusId;
          it.bigUom = it.uom;
          it.uid = this.genID(10);
        });
        this.selRows = []; //切换页码清空勾选的行
        this.onlyShowChecked = false;
        this.pager.count = data.page.totalCount;
        this.dataCache = data.data; //缓存列表数据，前端分页
        this.dataList = data.data.slice(start, start + this.pager.pageSize);
      });
      // }
    },
    //生成不重复id/后端返回的列表没有唯一键
    genID(length) {
      return Number(
        Math.random()
          .toString()
          .substr(3, length) + Date.now()
      ).toString(36);
    },
    // 客户模糊搜索
    onSearch() {
      // this.dataSource = !searchText ? [] : [searchText, searchText.repeat(2), searchText.repeat(3)];
    },
    async onSelect(value, e) {
      // 获取绑定的key 传到需要的商品弹窗组件中 用于查询价格
      this.shopcisCode = e.data.key; // 后续可能修改
      this.customerName = value.slice(value.lastIndexOf(" ") + 1, value.length);
      var indexFirst = value.indexOf(" ");
      this.customerCode = value.slice(0, indexFirst);

      this.getMetarial();
      let data = {
        cisCode: this.$store.state.user.userInfo.customer.cisCode,
        userAccount: this.$store.state.user.userInfo.account.account,
        customerCode: value.slice(0, indexFirst)
      };
      getUserMessage(data).then(res => {

        var obj = JSON.parse(res.data);
        //销售组织
        var XSZZ = [];
        for (let item of obj.customerInfo.orgList) {
          for (let key in item) {
            XSZZ.push({
              key,
              value: item[key]
            });
          }
        }
        if (XSZZ.length > 0) {
          this.receive = XSZZ[0].value;
          this.receiveID = XSZZ[0].key;
          this.orderDis = XSZZ;
          this.xszzId = XSZZ[0].value;
        } else {
          this.receive = "";
          this.receiveID = "";
          this.orderDis = [];
          this.xszzId = "";
        }
        this.fhWarehouse(this.customerCode);
        // 收货地址
        var SHDZ = [];
        // for (let item of obj.customerInfo.customerAddressAllList) {
        //   for (let key in item) {
        //     SHDZ.push({
        //       id: key,
        //       address: item[key]
        //     });
        //   }
        // }
        // 换
        let adressdata = {
          orgId: this.receiveID,
          cisCode: this.shopcisCode
        };
        this.shippingAddress = [];
        getAddress(adressdata).then(res => {
          if (res.data && res.data.list.length > 0) {

            SHDZ = res.data.list;
            // SHDZ.forEach(item=>{
            //   SHDZ.push({
            //     id: item.id,
            //     address: item.address
            //   });
            // })
          }

          if (SHDZ.length > 0) {
            this.shippingAddres = [];
            this.receivehis = SHDZ[0].address;
            this.officeCode = SHDZ[0].id;
            this.shippingAddress = SHDZ;
            this.shdzId = SHDZ[0].id;

            // 获取默认第一个的数据 如果状态为失效 就弹出提示
            let obj = SHDZ.find((i) => {
              return i.id === SHDZ[0].id;//筛选出匹配数据
            });
            if (obj.regionStatus == "D") {
              this.$message.warning("由于行政区划调整，请您及时更新您的收货地址信息", 5);
              this.receivehis = "";
              this.officeCode = "";
              this.shdzId = "";
              return;
            }
          } else {
            this.receivehis = "";
            this.officeCode = "";
            this.shippingAddress = [];
            this.shdzId = "";
          }

        });


        // 入库仓库
        var RKCK = [];
        for (let item of obj.customerInfo.inWarehouseList) {
          for (let key in item) {
            RKCK.push({
              key,
              value: item[key]
            });
          }
        }
        if (RKCK.length > 0) {
          this.receiveRK = RKCK[0].value;
          this.receiveRKID = RKCK[0].key;
          this.orderDisRK = RKCK;
        }
        this.billToId = obj.customerInfo.cisCode;
      });
    },
    onChange() {
      let data = {
        cisCode: this.$store.state.user.userInfo.customer.cisCode,
        page: {
          pageNo: 1,
          pageSize: 500
        },
        filterStr: ""
      };
      getUser(data).then(res => {
        var obj = JSON.parse(res.data);
        var newObj = obj.data;
        var realArray = [];
        for (var i = 0; i < newObj.length; i++) {
          let newItem =
            newObj[i].customerCode +
            " " +
            newObj[i].customerName +
            " " +
            newObj[i].legalPerson;
          // 8.11 后端添加cisCode参数，数据结构需要修改
          realArray.push({
            name: newItem,
            cisCode: newObj[i].customerCisCode
          });
        }
        this.dataSource = realArray;
        this.timeout = null;
      });
    },
    editRow(item) {
      this.rowSelectionType = "radio";
      this.searchIpt = item.model;
      this.selRow = item;
      this.isVisible = true;
      this.getData();
    },
    addProduct(productList) {
      productList = productList.filter(item => {
        if (item.productCode && item.productName) {
          return true;
        } else {
          // this.$message.warning("存在信息不全的产品，已去除！");
          const msg = this.$configTotal({
            route: this.$route.fullPath,
            id: "14180000067"
          });
          this.$message.warning(msg && msg.msgDesc);
          return false;
        }
      });
      if (this.rowSelectionType == "checkbox") {
        let invMap = {};
        // 所有的 组织 仓库 产品
        let uniqueInvKeyArr = [];
        let nowProdutct = productList[0];
        // 如果数组大于0
        if (this.productList.length > 0) {
          for (let i = 0; i < this.productList.length; i++) {
            let product = this.productList[i];
            uniqueInvKeyArr.push(product.orgCode + "_" + product.gicWarehouse + "_" + product.productCode);
            let key = product.orgCode + "_" + product.gicWarehouse + "_" + product.productCode + "_" + product.invStatusId +
              "_" + product.invStatusType;
            invMap[key] = product;
          }

          let uniqueInvKey = nowProdutct.orgCode + "_" + nowProdutct.gicWarehouse + "_" + nowProdutct.productCode;
          let invMapKey = uniqueInvKey + "_" + nowProdutct.invStatusId + "_" + nowProdutct.invStatusType;
          let invProduct = invMap[invMapKey];
          // 如果组织型号补差类型仓库质量等级都一样 原来有的数据上数量+1
          if (invProduct) {
            invProduct.borderedQty += 1;
            this.$message.info("添加成功，请关闭弹窗或继续添加!");
            return;
          } else {
            if (uniqueInvKeyArr.indexOf(uniqueInvKey) > -1) {
              //不能重复
              this.$message.warning("相同产品质量等级和补差类型必须保持一致，请重新选择!");
              return;
            } else {
              nowProdutct.checked = true;
              nowProdutct.itemId = "";
              nowProdutct.bprice = nowProdutct.retailPrice;
              this.productList.push(nowProdutct);
              this.$message.info("添加成功，请关闭弹窗或继续添加!");
              return;
            }
          }
        } else {
          uniqueInvKeyArr.push(nowProdutct.orgCode + "_" + nowProdutct.gicWarehouse + "_" + nowProdutct.productCode);
          let key = nowProdutct.orgCode + "_" + nowProdutct.gicWarehouse + "_" + nowProdutct.productCode + "_" +
            nowProdutct.invStatusId + "_" + productList[0].invStatusType;
          invMap[key] = nowProdutct;
          nowProdutct.checked = true;
          nowProdutct.itemId = "";
          nowProdutct.bprice = nowProdutct.retailPrice;
          this.productList.push(nowProdutct);
          this.$message.info("添加成功，请关闭弹窗或继续添加!");
          return;
        }

      } else {
        if (productList.length > 0) {
          let pro = productList[0];
          const index = this.productList.findIndex(
            it => it.productCode == this.selRow.productCode
          );
          pro = {
            ...pro,
            checked: true,
            itemId: "",
            bprice: this.productList[index].bprice
          };
          this.$set(this.productList, index, pro);
        }
      }
    },
    // 发货仓库
    // fhWarehouse(cisCode) {
    //   let data = {
    //     cisCode,
    //     userAccount: this.$store.state.user.userInfo.account.account,
    //     type: "fhck",
    //     warehouseType:''
    //   };
    //   getSupplierS(data).then(res => {
    //     var obj = JSON.parse(res.data);
    //     var arr = [];
    //
    //     for (let item of obj.data) {
    //       for (let key in item) {
    //         arr.push({
    //           key,
    //           value: item[key]
    //         });
    //       }
    //     }
    //     if (arr.length > 0) {
    //       this.receiveFH = arr[0].value;
    //       this.receiveFHID = arr[0].key;
    //       this.orderDisFH = arr;
    //       this.fhckId = arr[0].value;
    //     } else {
    //       this.receiveFH = "";
    //       this.receiveFHID = "";
    //       this.orderDisFH = [];
    //       this.fhckId = "";
    //     }
    //   });
    // },
    fhWarehouse(cisCode) {
      this.pageLoadFlag = true;
      let data = {
        cisCode,
        userAccount: this.$store.state.user.userInfo.account.account,
        orgId: this.receiveID
      };
      getWarehouseListApi(data).then(res => {
        var obj = JSON.parse(res.data);
        var arr = [];
        arr = obj.data;
        //
        if (arr.length > 0) {
          this.receiveFH = arr[0].name;
          this.receiveFHID = arr[0].cId;
          this.orderDisFH = arr;
          this.fhckId = arr[0].name;
        } else {
          this.receiveFH = "";
          this.receiveFHID = "";
          this.orderDisFH = [];
          this.fhckId = "";
        }
      });
      this.pageLoadFlag = false;
    },
    // dms配送方式
    getPS() {
      getDeliveryMode({}).then(res => {
        let obj;
        if (typeof res.data == "string") {
          obj = JSON.parse(res.data);
        } else {
          obj = res.data;
        }
        // var obj = JSON.parse(res.data);
        var arr = [];
        for (let item of obj.data) {
          for (let key in item) {
            arr.push({
              id: key,
              propertyName: item[key]
            });
          }
        }
        this.deliveryOptions = arr;
        this.trans = arr[0].id;
      });
    },
    // 业务员
    getywy() {
      let data = {
        cisCode: this.$store.state.user.userInfo.customer.cisCode,
        userAccount: this.$store.state.user.userInfo.account.account,
        // userAccount: this.$store.state.user.userInfo.customer.customerCode,
        type: "ywy"
      };
      getSupplierS(data).then(res => {
        var obj = JSON.parse(res.data);
        this.ywy = Object.keys(obj.data[0])[0];
        this.ywyname = obj.data[0][this.ywy];
      });
    },
    // 开票方
    cglrywy() {
      let data = {
        cisCode: this.$store.state.user.userInfo.customer.cisCode,
        userAccount: this.$store.state.user.userInfo.account.account,
        // userAccount: this.$store.state.user.userInfo.customer.customerCode,
        type: "kpf"
      };
      getSupplierS(data).then(res => {
        var obj = JSON.parse(res.data);
        this.kpf = Object.keys(obj.data[0])[0];
      });
    },
    // 查询商品行信息
    getMessage() {
      let params = {
        orgAngGroup: this.RouterParams.orgAndGroup,
        type: this.RouterParams.purchaseType,
        ids: this.RouterParams.carts
      };
      getCustomer(params).then(res => {
        var objs = res.data.cartDTOs;
        var total = 0,
          totaleMoney = 0,
          objitem = {},
          arr = [],
          totalVol = 0,
          id = [],
          orgId = [],
          orgCode = [],
          goodsarray = res.data.cartDTOs;
        // 供应商编码
        this.supplierCode = objs[0].agentCode;
        // 商品行信息
        for (let j = 0; j < objs.length; j++) {
          total = objs[j].quantity + total;
          totalVol = objs[j].loadVolume * objs[j].quantity + totalVol;
          id.push(objs[j].productId);
          orgId.push(objs[j].orgId);
          orgCode.push(objs[j].orgCode);
          objitem = {
            colour: objs[j].color == null ? "" : objs[j].color,
            amount: objs[j].quantity * objs[j].price,
            // 产品编码
            productCode: objs[j].productId,
            // invStatusId:obj[i].
            price: this.goodsPrice,
            model: objs[j].productName == null ? "" : objs[j].productName,
            invStatusType:
              objs[j].makeUpTypeCode == null ? "" : objs[j].makeUpTypeCode,
            orderedQty: objs[j].quantity == null ? "" : objs[j].quantity,
            priceId: objs[j].priceId == null ? "" : objs[j].priceId,
            productName: objs[j].fullName == null ? "" : objs[j].fullName,
            invStatusId: "",
            cartId: objs[j].id,
            loadVolume: objs[j].loadVolume
          };
          arr.push(objitem);
        }
        this.purchaseOrderItem = arr;
        // 查询商品价格信息
        id = id.join(",");
        orgId = orgId.join(",");
        orgCode = orgCode.join(",");
        let data = {
          code: id,
          orgId: orgId,
          orgCode: orgCode
        };
        goodsList(data).then(res => {
          for (let i = 0; i < res.data.length; i++) {
            // if (objs[i].id == )
            // this.GoodsList.price = res.data[i].price;
            totaleMoney = res.data[i].price * objs[i].quantity + totaleMoney;
            goodsarray[i].price = res.data[i].price;
            this.purchaseOrderItem[i].price = res.data[i].price;
            this.purchaseOrderItem[i].amount =
              res.data[i].price * objs[i].quantity;
          }
          // alert(totaleMoney);
          this.goodsPrice = totaleMoney;
          this.totalAmount = totaleMoney;
        });
        this.GoodsList = goodsarray;
        this.goodsNum = total;
        this.goodsVolume = totalVol;
        this.totalOrderedQty = total;
      });
    },
    // 查询库存数
    stock(goodsData, i) {
      getNumber({
        code: goodsData.code,
        orgId: goodsData.orgId
      }).then(resD => {
        var newObject = Object.assign(this.GoodsList[i], resD.data[i]);
        this.GoodsList[i] = newObject;
      });
    },
    // 省 选择
    handleProvinceChange(value, e) {
      this.proObj.proviceCode = e.key;
      this.choseCity(e.key);
    },
    // 获取城市
    choseCity(code) {
      let data = {
        proviceCode: code
      };
      cityChange(data)
        .then(res => {
          this.getCity = res.data;
          //获取城镇区
          this.getTowns(res.data[0].cityCode);
          this.proObj.cityCode = res.data[0].cityCode;
        })
        .catch(err => {
          console.log(err);
        });
    },
    // 获取城镇区
    getTowns(code) {
      let data = {
        cityCode: code
      };
      townChange(data)
        .then(res => {
          this.getTown = res.data;
          this.proObj.townCode = res.data[0].districtCode;
          this.getqu(res.data[0].districtCode);
        })
        .catch(err => {
          console.log(err);
        });
    },
    // 获取城镇下一级 town
    getqu(code) {
      let data = {
        districtCode: code
      };
      getQu(data)
        .then(res => {
          this.qu = res.data;
          this.proObj.zCode = res.data[0].townCode;
        })
        .catch(err => {
          console.log(err);
        });
    },
    // 城市选择
    handleCityChange(e) {
      this.proObj.cityCode = e;
    },
    // 城镇选择
    handleTownChange(e) {
      this.proObj.townCode = e;
      this.getqu(e);
    },
    // 城镇下一级 选择
    handleZhenChange(e) {
      this.proObj.zCode = e;
    },
    showModal() {
      this.visible = true;
    },
    handleOk(e) {
      this.visible = false;
    },

    onGiveType(e) {
      this.trans = this.deliveryOptions[e.target.value].id;
    },
    // 销售类型
    onGiveTypeLX(e) {
      this.XSLX = this.saleOptions[e.target.value].id;
    },
    onSubmit() {
      //form
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          alert("submit!");
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm() {
      this.$refs.ruleForm.resetFields();
    },
    // 点击选择开票户头
    choseAccount() {
      // axios请求后台开票户头
    },

    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    // 用户级联选择改变
    cityChange() {
    },
    // 获取后台开关是否开启了统仓
    // 只查统仓那个需求需要通过后台开关量来控制
    getType() {
      let data = {
        key: "QD_ONLY_SHARE_STORE"
      };
      sysconfig(data).then(res => {
        this.shareType = res.data.data;
      });

    },
    async sumbit() {

      const salesOrderItem = JSON.parse(
        JSON.stringify(this.productList.filter(it => it.checked))
      );
      // 如果后台开关为Y
      if (this.shareType == "Y") {
        if (salesOrderItem.length <= 0) {
          this.$message.warning("请录入商品信息");
          return;
        }
        var num = 0
        for (let i = 0; i < salesOrderItem.length; i++) {
          salesOrderItem[i].shareType = ''
          let shareData = {
            orgId: salesOrderItem[i].orgCode,
            matklId: salesOrderItem[i].materialGroupCode
          };
          const res = await queryShareFlag(shareData);
          salesOrderItem[i].shareType = res.data.data
          if (salesOrderItem[i].shareType == 'Y' && salesOrderItem[i].gicWarehouseType == "70") {
            num = num+1
          }
        }
        // 如果数量大于0 就提示
        if(num > 0) {
          //  渠道订单录入点击提交时，查询光伟的接口（入参：组织、物料组，返回值：Y、N）校验物料组是否开启共享
          this.$message.warning("提示： 开通共享仓的商家只能从统仓发货");
          this.isLoading = false
          return
        } else {
          this.submitOrder();
        }
      } else {
        this.submitOrder();
      }


    },
    // dms 订单提交
    submitOrder() {
      if (!this.mohusearch) {
        // this.$message.warning("请选择渠道客户");
        const msg = this.$configTotal({
          route: this.$route.fullPath,
          id: "14180000069"
        });
        this.$message.warning(msg && msg.msgDesc);
        return;
      }

      if (!this.trans) {
        // this.$message.warning("请选择配送方式");
        const msg = this.$configTotal({
          route: this.$route.fullPath,
          id: "14180000071"
        });
        this.$message.warning(msg && msg.msgDesc);
        return;
      }
      if (!this.officeCode) {
        // this.$message.warning("请选择收货地址");
        const msg = this.$configTotal({
          route: this.$route.fullPath,
          id: "14180000072"
        });
        this.$message.warning(msg && msg.msgDesc);
        return;
      }
      if (!this.XSLX) {
        // this.$message.warning("请选择销售类型");
        const msg = this.$configTotal({
          route: this.$route.fullPath,
          id: "14180000073"
        });
        this.$message.warning(msg && msg.msgDesc);
        return;
      }

      // 当前日期
      var now = new Date();
      var year = now.getFullYear(); //得到年份
      var month = now.getMonth() + 1; //得到月份
      var date = now.getDate(); //得到日期
      // var day = now.getDay(); //得到周几
      var hour = now.getHours(); //得到小时
      var minu = now.getMinutes(); //得到分钟
      var sec = now.getSeconds(); //得到秒
      var time = "";
      time =
        year + "-" + month + "-" + date + " " + hour + ":" + minu + ":" + sec;
      var time2 = year + "-" + month + "-" + date;
      // const salesOrderItem = JSON.parse(
      //   JSON.stringify(this.productList.filter(it => it.checked))
      // );
      // salesOrderItem.forEach(it => {
      //   it.bdemandQty = it.borderedQty;
      //   //控制补差类型
      //   // if(!this.isEnableOrNot){
      //   //   it.invStatusType = ''
      //   // }
      // });

      const salesOrderItem = [];
      this.productList.forEach(item => {
        if (item.checked) {
          salesOrderItem.push({
            productCode: item.productCode,
            materialCode: item.materialCode,
            model: item.model,
            invStatus: item.invStatusId,
            invStatusType: item.invStatusType,
            borderedQty: item.borderedQty,
            bdemandQty: item.borderedQty,
            bprice: item.bprice,
            amount: item.amount,
            warehouseId: item.gicWarehouse,
            orgId: item.orgCode,
            standardPrice:item.standardPrice?item.standardPrice:''

          });
        }
      });

      if (salesOrderItem.length < 1) {
        // this.$message.warning("请至少选择一个商品！");
        const msg = this.$configTotal({
          route: this.$route.fullPath,
          id: "14180000075"
        });
        this.$message.warning(msg && msg.msgDesc);
        return;
      }
      for (let i = 0; i < this.productList.length; i++) {
        const item = this.productList[i];
        if (item.checked) {
          if (!item.bprice) {
            this.$message.warning(`请检查第${i + 1}个商品价格！`);
            return;
          }
          if (!item.borderedQty) {
            this.$message.warning(`请检查第${i + 1}个商品数量！`);
            return;
          }
        }
      }

      this.isLoading = true;
      let salesOrder = {
        retailType: this.XSLX,
        addressId: this.officeCode,
        message: this.BZvalue,
        customerName: this.customerName,
        customerCode: this.customerCode,
        // 开票方id
        billFromId: this.kpf,
        // 收货单位id
        billToId: this.billToId,
        // 业务员编码
        sellerCode: this.ywy,
        sellerName: this.ywyname,
        // 入库仓库this.receiveRKID
        inWarehouseId: "",
        // 订单金额
        totalAmount: this.$refs.productList.getTotalMoney(),
        deliveryMode: this.trans,
        edt: this.startTime?this.startTime:time2,
        dataSource: "xtw_pc",
        // 销售单明细，商品行信息
        salesOrderItem: salesOrderItem

      };

      let data = {
        cisCode: this.$store.state.user.userInfo.customer.cisCode,
        userAccount: this.$store.state.user.userInfo.account.account,
        salesOrder: salesOrder,
        status: "submitted"
      };
      orderSubmit(data)
        .then(res => {
          var obj = JSON.parse(res.data);
          if (obj.code == "1") {
            this.$message.warning(obj.msg);
          } else if (obj.code == "0") {
            this.$router.push({
              path: "/confirm/inputSuccess",
              query: {
                b2bOrderCode: obj.msg,
                type: "normal",
                orgName: "订单号"
              }
            });
          }
          this.isLoading = false;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    // 订单类型切换
    orderChange(type) {
      this.$emit("changeType", type);
    },
    // 时间更改
    handleTimeChange(e, defaultTime) {
      this.startTime = defaultTime;
    },
    // // 控制可选时间范围
    // disabledDate(current) {
    //   return current && current < moment().endOf("day");
    // },
    // 控制可选时间范围
    disabledDate(current) {
      let nowDate = moment().format("YYYY-MM-DD")
      // return current < moment().startOf('days') || current > new Date(this.$util.getDate30())
      return current < moment(nowDate) || current > moment(nowDate + ' 23:59:59').add(29, 'd')
    },
    // 获取子组件选中商品
    sonCheckList(data) {
      let cheacked = this.sonChectItme.indexOf(data);
      if (this.sonChectItme.indexOf(data) == -1) {
        this.sonChectItme.push(data);
      } else {
        this.sonChectItme.splice(cheacked, 1);
      }
    },
    //   点击返回购物车
    goCar() {
      this.$router.push("/channelOrder/orderList?type=normal");
    },
    // 销售组织点击选择
    receiveAddress() {
      this.receiveAdd = !this.receiveAdd;
    },
    // 发货仓库选择
    receiveAddressFH() {
      this.receiveAddFH = !this.receiveAddFH;
    },
    // 入库仓库
    receiveAddressRK() {
      this.receiveAddRK = !this.receiveAddRK;
    },
    // 销售组织下拉框选择
    async handleChangeAddress(value, e) {
      this.receive = value;
      // 收货地址 选中 id
      this.receiveID = e.key;
      // this.SAddressChange(e.key)
      this.receiveAdd = !this.receiveAdd;
      this.fhWarehouse();

      let adressdata = {
        orgId: this.receiveID,
        cisCode: this.shopcisCode
      };
      var SHDZ1 = [];
      this.shippingAddress = [];
      await getAddress(adressdata).then(res => {
        if (res.data && res.data.list.length > 0) {
          SHDZ1 = res.data.list;
          // SHDZ1.forEach(item => {
          //   SHDZ1.push({
          //     id: item.id,
          //     address: item.address
          //   });
          // })
        } else {
          SHDZ1 = [];
        }
      });
      if (SHDZ1.length > 0) {
        this.shippingAddres = [];
        this.receivehis = SHDZ1[0].address;
        this.officeCode = SHDZ1[0].id;
        this.shippingAddress = SHDZ1;
        this.shdzId = SHDZ1[0].id;
      } else {
        this.receivehis = "";
        this.officeCode = "";
        this.shippingAddress = [];
        this.shdzId = "";
      }
    },
    // 发货地址下拉
    handleChangeAddressFH(value, e) {
      this.receiveFH = value;
      // 收货地址 选中 id
      this.receiveFHID = e.key;
      // this.SAddressChange(e.key)
      this.receiveAddFH = !this.receiveAddFH;
      this.productList = [];
    },
    // 入库地址下拉
    handleChangeAddressRK(value, e) {
      this.receiveAddRK = !this.receiveAddRK;
      this.receiveRK = value;
      // 收货地址 选中 id
      this.receiveRK = e.key;
      // this.SAddressChange(e.key)
      this.receiveRKID = !this.receiveAddFH;
    },
    // 收货地址
    hishandleChangeAddress(value, e) {
      // 筛选出来当前选择的数据
      let obj = this.shippingAddress.find((i) => {//listData就是上面的数据源
        return i.id === e.key;//筛选出匹配数据
      });
      // 如果选择的地址状态为D 说明地址失效 弹出提示
      if (obj.regionStatus == "D") {
        this.$message.warning("由于行政区划调整，请您及时更新您的收货地址信息", 5);
        this.receivehis = "";
        this.distributionAdd.DISCODE = "";
        this.officeCode = "";
        return;
      }
      this.distributionAdd.DISCODE = e.key;
      this.officeCode = e.key;
      this.hisenseOff = !this.hisenseOff;
      this.receivehis = this.shippingAddress.filter(
        it => it.id == value
      )[0].address;
    },
    // 切换收货地址
    SAddressChange(id) {
      showAnother({
        id
      })
        .then(res => {
        })
        .catch(err => {
          console.log(err);
        });
    },
    // 分销商显示隐藏
    choseDistr() {
      this.distr = !this.distr;
    },
    // 切换分销商
    handleChangeDistr(value, e) {
      this.distrName = value;
      this.DISADDPARAMS.customerId = e.key;
      this.getDISADDList();
    },
    // 分销商切换获取分销商地址list
    getDISADDList() {
      let data = this.DISADDPARAMS;
      getDISAdd(data)
        .then(res => {
          this.choseDistribution = res.data;
          this.distributionAdd.DISCODE = res.data[0].id;
        })
        .catch(err => {
          console.log(err);
        });
    },
    distrAddShow() {
      this.distrAdd = !this.distrAdd;
    },
    // 选择经销商地址
    handleChangeDistrAdd(value, e) {
      this.distributionAdd.DISCODE = e.data.key;
      this.distributionAdd.DISADD = value;
      this.distrAddress = value;
    },

    addItem() {
      //this.items.push(`New item ${index++}`);
    },
    // 监听输入变化
    handleChangeInt(e) {
    },
    //弹框行内编辑数量
    rowChange() {
    },
    // 弹框
    openAdd() {
      if (!this.mohusearch) {
        // this.$message.warning("请选择渠道客户");
        const msg = this.$configTotal({
          route: this.$route.fullPath,
          id: "14180000069"
        });
        this.$message.warning(msg && msg.msgDesc);
        return;
      }
      this.rowSelectionType = "checkbox";
      this.isVisible = true;
      if (!this.searchIpt || this.searchIpt.length < 2) {
        this.selRows = []; //切换页码清空勾选的行
        this.onlyShowChecked = false;
        this.pager.count = 0;
        this.dataCache = []; //缓存列表数据，前端分页
        this.dataList = [];
        return;
      }
      this.getData();
    },
    // 海信办事处
    hisenseOffice() {
      this.hisenseOff = !this.hisenseOff;
    }
  }
};

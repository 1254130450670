import http from "@/utils/request";

import dmshttp from "@/utils/dmsrequest";

// 根据门店获取物料组
export function findMaterialByCustomer(data) {
    return dmshttp({
        url: 'findMaterialByCustomer',
        data
    })
}
// 查询商家地址

export function getAddress(data) {
    return http({
        url: '/address/changeDistributionAddressByCode.nd',
        method: 'post',
        data
    })
}

// 查询 供应商 结算单位等
export function getSupplierS(data) {
    return dmshttp({
        url: 'findBaseData',
        data
    })
}

// 用户模糊搜索
export function getUser(data) {
    return dmshttp({
        url: 'findNormalSalesOrderCustomer',
        data
    })
}

//  查询渠道订单客户信息
export function getUserMessage(data) {
    return dmshttp({
        url: 'normalSalesOrderCustomerInfo',
        data
    })
}


//获取商品列表
export function getProductList(data) {
    return dmshttp({
        url: 'findInventoryList',
        data
    })
}
// 配送方式
export function getDeliveryMode(data) {
    return dmshttp({
        url: 'getDeliveryMode',
        data
    })
}

// 查询商品信息 
export function getCustomer(params) {
    return http({
        url: '/cart/showCartList.nd',
        method: 'get',
        params
    })
}
// dms 订单提交
export function orderSubmit(data) {
    // createNormalSalesOrder
    return dmshttp({
        url: 'createBatchNormalSalesOrder',
        data
    })
}

//  //分享商渠道采购



// 渠道采购 配送方式等查询
export function specialZone(params) {
    return http({
        url: '/cart/settlement.nd',
        method: 'get',
        params
    })
}





//时效信息
export function orderWeek(data) {
    return http({
        method: "post",
        url: "/cart/orderWeek.nd",
        data
    });
}
// 购物车查询  
export function orderCar(params) {
    return http({
        url: '/cart/settlement.nd',
        method: 'get',
        params
    })
}
// 统仓地址联系人address/changeCustomerAddress.nd
export function orderAddress(data) {
    return http({
        url: '/address/changeCustomerAddress.nd',
        method: 'post',
        data
    })
}

// 分销商 分销地址

export function showAnother(params) {
    return http({
        url: '/cart/showAnotherOrderList.nd',
        method: 'get',
        params
    })
}

// 收货地址切换 带出联系人
export function addessChange(params) {
    return http({
        url: '/address/changeCustomerAddress.nd',
        method: 'get',
        params
    })
}

// 获取城市接口
export function cityChange(data) {
    return http({
        url: '/customer/getCity.nd',
        method: 'post',
        data
    })
}

// 获取城镇区
export function townChange(data) {
    return http({
        url: '/customer/getDistrict.nd',
        method: 'post',
        data
    })
}

// 获取城镇区下一级
export function getQu(data) {
    return http({
        url: '/customer/getTown.nd',
        method: 'post',
        data
    })
}

// 分销切换 请求分销商地址
export function getDISAdd(data) {
    return http({
        url: '/address/changeDistributionAddress.nd',
        method: 'post',
        data
    })
}
// 地址切换 获取联系人
export function getPhone(data) {
    return http({
        url: '/address/changeCustomerAddress.nd',
        method: 'post',
        data
    })
}

// 要求到货周 
export function getWeek(data) {
    return http({
        url: '/cart/orderWeek.nd',
        method: 'post',
        data
    })
}

// 结算商品列表  价格信息等 
export function goodsList(data) {
    return http({
        url: '/product/getPrices.nd',
        method: 'post',
        data
    })
}
// 获取库存数 
export function getNumber(data) {
    return http({
        url: '/product/getStocks.nd',
        method: 'post',
        data
    })
}

// 购物车订单提交
export function cartSubmit(data) {
    return http({
        url: '/cart/saveOrder.nd',
        method: 'post',
        data
    })
}
// 查看商品列表
export function quotaDeatail(data) {
    return dmshttp({
        url: 'findInventoryList',
        data
    })
}

export function queryShareFlag(data) {
    return http({
        url: '/customer/queryShareFlag.nd',
        method: 'post',
        data
    })
}
// 发货仓库
export function getWarehouseListApi(data) {
    return dmshttp({
        url: "getWarehouseList",
        data
    });
}
export function sysconfig(data) {
    return http({
        url: '/comm/sysconfig.nd',
        method: 'post',
        data
    })
}